<template>
    <b-modal v-model="show"
             centered
             no-close-on-backdrop
             @hide="closeModal">
        <template #modal-title>
            <p class="title-text">
                <span v-if="!isEdit">新增</span><span v-else>編輯</span>賽程配對資料
            </p>
        </template>
        <div class="control-row">
            <div class="form-label">
                <span class="label-text">開始時間</span>
                <date-picker v-model="form.start_at"
                             name="start_at"
                             type="datetime"
                             value-type="format"
                             format="YYYY-MM-DD HH:mm:ss"
                             placeholder="請選擇開始時間"
                             :open.sync="isStartPickerOpen"
                             @change="startPickerHandle"/>
            </div>
            <div class="form-label">
                <span class="label-text">結束時間</span>
                <date-picker v-model="form.end_at"
                             name="end_at"
                             class="end-time"
                             :class="{'error-control':errors.has('end_at')}"
                             type="datetime"
                             value-type="format"
                             format="YYYY-MM-DD HH:mm:ss"
                             placeholder="請選擇結束時間"
                             :open.sync="isEndPickerOpen"
                             @change="endPickerHandle"
                             v-validate="'scheduleDateValidate:start_at'"/>
                <span v-show="errors.has('end_at:scheduleDateValidate')" class="error-text">結束時間不可早於開始時間</span>
            </div>
        </div>
        <div class="control-row">
            <div class="form-label">
                <span class="label-text">場地</span>
                <common-select v-model="form.field_id"
                               :select-list="fieldList"
                               find-key="id"
                               display-label="name"
                               name="field"
                               :class="{'error-control': errors.has('field:required')}"/>
            </div>
            <div class="form-label">
                <span class="label-text">編號</span>
                <input v-model="form.number"
                       type="text"
                       name="number"
                       class="form-control"
                       :class="{'error-control': errors.has('number:decimal')}"
                       placeholder="請輸入編號"
                       v-validate="'decimal:0'"/>
            </div>
        </div>
        <p class="text-left team-title">隊伍1</p>
        <div class="control-row">
            <div class="form-label">
                <span class="label-text">參賽者1</span>
                <common-select v-model="form.competition_schedule_records[0]['user_id']"
                               name="user1"
                               find-key="user_id"
                               :class="{'error-control': errors.has('user1:required')}"
                               :select-list="userList"
                               :is-search="true"
                               display-label="name"
                               :row-sort="1"
                               @input="userHandle"
                               v-validate="'required'"/>
            </div>
            <div class="form-label">
                <span class="label-text">參賽者2</span>
                <common-select v-model="form.competition_schedule_records[1]['user_id']"
                               name="user2"
                               find-key="user_id"
                               :class="{'error-control': errors.has('user2:required')}"
                               :select-list="userList"
                               :is-search="true"
                               display-label="name"
                               :row-sort="2"
                               @input="userHandle"/>
            </div>
        </div>
        <p class="text-left team-title">隊伍2</p>
        <div class="control-row">
            <div class="form-label">
                <span class="label-text">參賽者1</span>
                <common-select v-model="form.competition_schedule_records[2]['user_id']"
                               name="user3"
                               find-key="user_id"
                               :class="{'error-control': errors.has('user3:required')}"
                               :select-list="userList"
                               :is-search="true"
                               display-label="name"
                               :row-sort="3"
                               @input="userHandle"/>
            </div>
            <div class="form-label">
                <span class="label-text">參賽者2</span>
                <common-select v-model="form.competition_schedule_records[3]['user_id']"
                               name="user4"
                               find-key="user_id"
                               :class="{'error-control': errors.has('user4:required')}"
                               :select-list="userList"
                               :is-search="true"
                               display-label="name"
                               :row-sort="4"
                               @input="userHandle"/>
            </div>
        </div>
        <template #modal-footer>
            <button type="button" class="btn gray-btn" @click="closeModal">取消</button>
            <button type="button" class="btn green-btn" @click="addSchedule" :disabled="isLoading">
                <clip-loader v-if="isLoading" class="loading py-1 px-2" color="white" size="15px"/>
                <span v-else>
                    <template v-if="!isEdit">新增</template>
                    <template v-else>儲存</template>
                </span>
            </button>
        </template>
    </b-modal>
</template>
<script>
import CommonSelect from '../Common/CommonSelect';

export default {
    name: 'ScheduleModal',
    props: {
        isLoading: Boolean,
        value: {
            type: Boolean,
            default: true
        },
        isEdit: Boolean,
        fieldList: Array,
        userList: {
            type: Array,
            default: []
        },
        editData: {
            type: Object,
            default: {}
        }
    },
    components: {
        CommonSelect
    },
    watch: {
        value(newVal) {
            this.resetValidate();
            this.show = newVal;
        },
        editData: {
            handler(newVal) {
                if(newVal && this.show) {
                    this.form = _.cloneDeep(newVal);
                    _.times(4, index => {
                        const findUser = _.find(this.form.competition_schedule_records, item => item.sort === index + 1);
                        if(!findUser) {
                            this.form.competition_schedule_records.push({
                                sort: index + 1,
                                user_id: null
                            });
                        }
                    });
                    _.sortBy(this.form.competition_schedule_records, ['sort']);
                } else {
                    this.resetForm();
                }
            },
            deep: true
        },
        'form.start_at': function(newVal) {
            if(newVal && this.form.end_at) {
                this.$validator.validate('end_at');
            }
        }
    },
    data() {
        return {
            show: this.value,
            isStartPickerOpen: false,
            isEndPickerOpen: false,
            form: {
                competition_schedule_records: [
                    {
                        sort: 1,
                        user_id: null
                    },
                    {
                        sort: 2,
                        user_id: null
                    },
                    {
                        sort: 3,
                        user_id: null
                    },
                    {
                        sort: 4,
                        user_id: null
                    }
                ]
            }
        };
    },
    created() {
        this.$validator.extend('scheduleDateValidate', {
            getMessage: '結束時間不能小於開始時間',
            validate: (value) => {
                return this.$moment(value).isSameOrAfter(this.$moment(this.form.start_at));
            }
        });
    },
    methods: {
        closeModal() {
            this.resetForm();
            this.$emit('input', false);
        },
        startPickerHandle(value, type) {
            // 結束時間 = 開始時間+15分
            if(value) {
                let date = new Date(value);
                let minutes = date.getMinutes();
                date.setMinutes(minutes + 15);
                this.form['end_at'] = this.dateFormat(date, 'YYYY-MM-DD HH:mm:ss');
            }

            if(type === 'second') {
                this.isStartPickerOpen = false;
            }
        },
        endPickerHandle(value, type) {
            if(type === 'second') {
                this.isEndPickerOpen = false;
            }
        },
        userHandle(emitData) {
            if(emitData.item) {
                this.form.competition_schedule_records[emitData.rowSort - 1].sort = emitData.rowSort;
                this.form.competition_schedule_records[emitData.rowSort - 1].user_id = emitData.item.user_id;
            } else {
                this.form.competition_schedule_records[emitData.rowSort - 1].sort = emitData.rowSort;
                this.form.competition_schedule_records[emitData.rowSort - 1].user_id = null;
            }
        },
        async addSchedule() {
            const isPass = await this.validate(true);
            if(!isPass) return;

            this.$emit('schedule-handle', this.form);
        },
        resetForm() {
            this.form = {
                competition_schedule_records: [
                    {
                        sort: 1,
                        user_id: null
                    },
                    {
                        sort: 2,
                        user_id: null
                    },
                    {
                        sort: 3,
                        user_id: null
                    },
                    {
                        sort: 4,
                        user_id: null
                    }
                ]
            };
        }
    }
};
</script>
<style lang="scss" scoped>
.team-title{
    padding:          5px;
    background-color: #EDEDED;
    margin-bottom:    10px;
}
.form-label{
    width: calc(100% / 2 - 5px);
}
.end-time{
    :deep(.mx-input:disabled){
        color: #333;
    }
}
</style>

<template>
    <section>
        <div class="d-flex flex-wrap align-items-start">
            <search-bar v-if="tableType === 1"
                        :competition-list="competitionList"
                        :search-types="searchTypes"
                        :search-form="searchForm"
                        @search="searchHandle"/>
            <button v-if="tableType === 1 && isExport" type="button" class="btn orange-btn mr-2" @click="exportReportHandler()" :disabled="isExporting">批量匯出</button>
            <button type="button" class="btn blue-btn mb-3 ml-auto" @click="modalOpen()">新增{{ tableType === 0 ? '賽事' : '賽程' }}</button>
        </div>
        <p v-show="tableType === 1" class="mb-2 text-red font-weight-bold">請先將使用者裝備配對完成後，再進行賽程配對</p>
        <div class="table-nav">
            <button type="button" class="btn nav-btn" :class="{'active':tableType === 0}" @click="tableType = 0">賽事</button>
            <button type="button" class="btn nav-btn" :class="{'active':tableType === 1}" @click="tableType = 1">賽程</button>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered" :class="tableType === 0 ? 'competition' : 'schedule'">
                <thead>
                <tr v-if="tableType === 1">
                    <th colspan="5"></th>
                    <th colspan="2">隊伍1</th>
                    <th colspan="2">隊伍2</th>
                    <th></th>
                </tr>
                <tr>
                    <th v-if="tableType === 1">
                        <input v-model="isExportAll" type="checkbox" @click="allExportHandler"/>
                    </th>
                    <template v-for="item in tableColumns">
                        <template v-if="item.formLabel === 'competition_schedule_records'">
                            <th v-for="user in 4" :key="`${item.formLabel}_${user}`">
                                {{ item.name }}{{ user }}
                            </th>
                        </template>
                        <th v-else :key="item.formLabel">
                            {{ item.name }}
                        </th>
                    </template>
                    <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="isLoading || isFetching">
                    <td :colspan="columnLen">
                        <clip-loader class="loading p-0" color="gray" size="30px"/>
                    </td>
                </tr>
                <template v-else-if="(!isLoading || !isFetching) && tableList.length !== 0">
                    <!--                    資料list-->
                    <tr v-for="(dataItem,dataIndex) in tableList" :key="`tr_${dataIndex}`">
                        <td v-if="tableType === 1">
                            <input v-model="dataItem.isExport" type="checkbox"/>
                        </td>
                        <!--                        欄位list-->
                        <template v-for="(columnItem,columnIndex) in tableColumns">
                            <template v-if="columnItem.formLabel === 'competition_schedule_records'">
                                <td v-for="userItem in 4" :key="`td_competition_schedule_records_${userItem}`">
                                    {{ findUser(dataItem, userItem) }}
                                </td>
                            </template>
                            <td v-else-if="columnItem.formLabel === 'field_id'">
                                {{ findField(dataItem) }}
                            </td>
                            <td v-else :key="`td_${columnIndex}`">
                                {{ dataItem[columnItem.formLabel] }}
                            </td>
                        </template>
                        <td>
                            <div class="operate">
                                <button type="button" class="btn blue-btn" @click="modalOpen(dataItem)">編輯</button>
                                <button v-if="tableType === 1" type="button" class="btn orange-btn" @click="exportReport(dataItem.competition_schedule_records)">匯出</button>
                                <button v-if="tableType === 0" type="button" class="btn orange-btn" @click="viewSchedule(dataItem)">查看賽程</button>
                                <button type="button" class="btn red-btn" @click="delModalHandle(dataItem)">刪除</button>
                            </div>
                        </td>
                    </tr>
                </template>
                <tr v-else>
                    <td :colspan="columnLen" class="text-center">無{{ tableType === 0 ? '賽事' : '賽程' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <!--        分頁-->
        <pagination v-if="tableType === 1"
                    v-model="pagination.currentPage"
                    :pagination="pagination"
                    @page-handle="fetchData"/>
        <schedule-modal v-model="scheduleModalShow"
                        :is-loading="isSubmitting"
                        :field-list="fieldList"
                        :is-edit="modal.isEdit"
                        :edit-data="modal.editData"
                        :user-list="userList"
                        @input="resetData"
                        @schedule-handle="scheduleHandle"/>
        <competition-modal v-model="competitionModalShow"
                           :is-loading="isSubmitting"
                           :is-edit="modal.isEdit"
                           :edit-data="modal.editData"
                           @input="resetData"
                           @competition-handle="competitionHandle"/>
        <common-modal v-model="delModal.show"
                      :is-loading="isSubmitting"
                      :has-close="true"
                      @input="resetData"
                      @confirm-handle="delSchedule">
            <template v-slot:modal-message>
                <p>確定刪除?</p>
                <p v-show="tableType === 0" class="modal-remind mt-1">(該賽事底下的所有賽程將會一併刪除)</p>
            </template>
            <template v-slot:modal-btn>確認</template>
        </common-modal>
        <user-select-modal v-model="userSelectModal.show"
                           :user-data="userSelectModal.userData"
                           :is-loading="isExporting"
                           @export-handler="exportReportHandler"/>
    </section>
</template>
<script>
import CommonModal from '@/components/Common/CommonModal';
import CompetitionModal from '@/components/CompetitionScheduleMgmt/CompetitionModal';
import Pagination from '@/components/Common/Pagination';
import SearchBar from '@/components/Common/SearchBar';
import ScheduleModal from '@/components/CompetitionScheduleMgmt/ScheduleModal';
import UserSelectModal from '@/components/CompetitionScheduleMgmt/UserSelectModal';
import {mapState, mapGetters, mapActions} from 'vuex';
import http from '@/config/http';

export default {
    name: 'CompetitionScheduleMgmt',
    components: {
        CommonModal,
        CompetitionModal,
        Pagination,
        SearchBar,
        ScheduleModal,
        UserSelectModal
    },
    computed: {
        ...mapState({
            userList: state => state.user.userList
        }),
        ...mapGetters({
            gymId: 'user/gymId'
        }),
        searchTypes() {
            return this.tableType === 0 ? [] : ['日期', '賽事'];
        },
        tableColumns() {
            return this.tableType === 0 ? this.competitionColumns : this.scheduleColumns;
        },
        columnLen() {
            return this.tableColumns.length + (this.tableType === 0 ? 1 : 5);
        },
        tableList() {
            return this.tableType === 0 ? this.competitionList : this.scheduleList;
        },
        isExport() {
            return _.some(this.scheduleList, item => item.isExport);
        }
    },
    watch: {
        tableType(newVal) {
            if(newVal === 0) {
                this.searchForm = {
                    competition_id: null
                };
            }
            this.fetchData();
        }
    },
    data() {
        return {
            tableType: 0, // 0:賽事; 1:賽程
            isLoading: false, // 讀取資料loading
            isFetching: false, // 單一api loading
            isSubmitting: false,
            isExportAll: false,
            isExporting: false,
            searchForm: {
                competition_id: null
            },
            competitionColumns: [
                {
                    formLabel: 'start_date',
                    name: '開始時間'
                },
                {
                    formLabel: 'end_date',
                    name: '結束時間'
                },
                {
                    formLabel: 'name',
                    name: '賽事名稱'
                },
                {
                    formLabel: 'competition_schedules_count',
                    name: '賽程數量'
                }
            ],
            scheduleColumns: [
                {
                    formLabel: 'number',
                    name: '編號'
                },
                {
                    formLabel: 'start_at',
                    name: '開始時間'
                },
                {
                    formLabel: 'end_at',
                    name: '結束時間'
                },
                {
                    formLabel: 'field_id',
                    name: '場地'
                },
                {
                    formLabel: 'competition_schedule_records',
                    name: '參賽者'
                }
            ],
            scheduleList: [],
            competitionList: [],
            fieldList: [],
            modal: {
                isEdit: false,
                editData: null
            },
            competitionModalShow: false,
            scheduleModalShow: false,
            delModal: {
                show: false,
                delId: null
            },
            userSelectModal: {
                show: false,
                userData: null,
                userId: null
            },
            pagination: {
                currentPage: 1,
                totalPage: 1
            }
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        ...mapActions('user', ['fetchUserList']),
        async fetchData() {
            this.isLoading = true;
            this.competitionList = [];
            this.scheduleList = [];
            await this.fetchCompetition();
            if(this.tableType === 1) {
                await this.fetchFieldList();
                this.searchForm.competition_id = this.searchForm.competition_id ? this.searchForm.competition_id : this.competitionList.length !== 0 ? this.competitionList[0].id : null;

                const findCompetition = this.searchForm.competition_id ? this.findCompetition(this.searchForm.competition_id) : null;
                this.searchForm.start_at = findCompetition?.start_date || this.$moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                this.searchForm.end_at = findCompetition?.end_date || this.$moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
                this.searchForm.date = [this.searchForm.start_at, this.searchForm.end_at];

                await this.fetchUserList();
                if(this.competitionList.length !== 0) {
                    await this.fetchSchedule();
                }
            } else {
                this.searchForm.competition_id = null;
            }
            this.isLoading = false;
        },
        // 賽事列表
        async fetchCompetition() {
            try {
                this.isFetching = !this.isLoading ?? false;
                const res = await this.$http.get('/competition', {
                    params: {
                        gym_id: this.gymId
                    }
                });
                this.competitionList = res.data.data;
            } catch(err) {
                this.toastError('賽事讀取失敗，請稍後再試');
            } finally {
                this.isFetching = false;
            }
        },
        // 賽程列表
        async fetchSchedule() {
            if(this.competitionList.length === 0) return;

            try {
                this.isFetching = !this.isLoading ?? false;
                const res = await this.$http.get('/competitionSchedule', {
                    params: {
                        competition_id: this.searchForm.competition_id,
                        start_at: this.searchForm.start_at,
                        end_at: this.searchForm.end_at,
                        per_page: 10,
                        page: this.pagination.currentPage
                    }
                });
                if(res.status === 200) {
                    const data = res.data.data;
                    this.pagination.totalPage = data.last_page;
                    this.scheduleList = _.map(data.data, item => {
                        item.isExport = false;
                        return item;
                    });
                }
            } catch(err) {
                this.toastError('賽程列表讀取失敗，請稍後再試');
            } finally {
                this.isFetching = false;
            }
        },
        async fetchFieldList() {
            try {
                const res = await this.$http.get('/field', {
                    params: {
                        gym_id: this.gymId
                    }
                });
                if(res.status === 200) {
                    const list = res.data.data;
                    this.fieldList = _.filter(list, item => item.is_display !== 0);
                }
            } catch(err) {
                this.toastError('場地清單獲取失敗，請稍後再試');
            }
        },
        // 尋找參賽者顯示於表格欄位
        findUser(data, sort) {
            return _.get(_.find(data.competition_schedule_records, {sort}), 'gym_customer.name', '');
        },
        // 尋找顯示數據場地名稱
        findField(data) {
            return _.get(_.find(this.fieldList, {id: data.field_id}), 'name', '');
        },
        findCompetition(id) {
            return _.find(this.competitionList, {id});
        },
        // 搜尋
        searchHandle(searchData) {
            this.searchForm = _.cloneDeep(searchData);

            const findCompetition = this.findCompetition(this.searchForm.competition_id);
            if(findCompetition) {
                const {start_date, end_date} = findCompetition;
                this.searchForm = {
                    ...this.searchForm,
                    start_at: start_date,
                    end_at: end_date,
                    date: [start_date, end_date]
                };
            }

            this.pagination.currentPage = 1;
            this.fetchSchedule();
        },
        // 新增或編輯賽事/賽程modal開啟
        modalOpen(data) {
            const permissionCheck = this.permissionCheck(this.tableType === 0 ? (data ? '編輯賽事' : '新增賽事') : (data ? '編輯賽程' : '新增賽程'));
            if(!permissionCheck) return;

            this.modal.isEdit = data ? true : false;
            if(data) {
                this.modal.editData = data;
            }

            if(this.tableType === 0) {
                this.competitionModalShow = true;
            } else {
                this.scheduleModalShow = true;
            }
        },
        // 新增或編輯賽事
        async competitionHandle(data) {
            const apiMethod = this.modal.isEdit ? 'put' : 'post';
            const formData = {
                start_date: data.start_date,
                end_date: data.end_date,
                name: data.name,
                ...(!this.modal.isEdit ? {type: 'gym', type_id: this.gymId} : {}),
                ...(this.modal.isEdit ? {id: data.id} : {})
            };
            try {
                this.isSubmitting = true;
                const res = await this.$http[apiMethod]('/competition', formData);
                if(res.status === 200) {
                    this.resetData();
                    await this.fetchCompetition();
                }
            } catch(err) {
                this.toastError(`${this.modal.isEdit ? '儲存' : '新增'}失敗，請稍後再試`);
            } finally {
                this.isSubmitting = false;
            }
        },
        // 新增或編輯賽程
        async scheduleHandle(data) {
            let competition_schedule_records;
            if(this.modal.isEdit) {
                competition_schedule_records = _.map(data.competition_schedule_records, item => {
                    return {
                        sort: item.sort,
                        user_id: item.user_id,
                        equipment_sensor_record_id: item.equipment_sensor_record_id
                    };
                });
            } else {
                competition_schedule_records = data.competition_schedule_records;
            }

            const idData = this.modal.isEdit ? {id: data.id} : {competition_id: this.searchForm.competition_id};
            const formData = {
                ...idData,
                number: data.number ? Number(data.number) : null,
                field_id: data.field_id,
                start_at: data.start_at,
                end_at: data.end_at,
                competition_schedule_records: _.filter(competition_schedule_records, item => item.user_id != null)
            };
            try {
                this.isSubmitting = true;
                let apiMethod = this.modal.isEdit ? 'put' : 'post';
                const res = await this.$http[apiMethod]('/competitionSchedule', formData);

                if(res.status === 200) {
                    this.toastSuccess(this.modal.isEdit ? '儲存成功' : '新增成功');
                    this.resetData();
                    this.pagination.currentPage = 1;
                    await this.fetchSchedule();
                }
            } catch(err) {
                const errData = err.response.data;
                if(errData?.start_at?.includes('The start at is invalid.') || errData?.end_at?.includes('The end at is invalid.')) {
                    this.toastError('該時間不在賽事區間內');
                } else {
                    this.commonToastError(err.response.status);
                }
            } finally {
                this.isSubmitting = false;
            }
        },
        // 查看賽程
        viewSchedule(data) {
            this.searchForm.competition_id = data.id;
            this.searchForm.date = [data.start_date, data.end_date];
            this.tableType = 1;
        },
        // 開啟刪除視窗
        delModalHandle(item) {
            const permissionCheck = this.permissionCheck(this.tableType === 0 ? '刪除賽事' : '刪除賽程');
            if(!permissionCheck) return;

            this.delModal.delId = item.id;
            this.delModal.show = true;
        },
        // 刪除賽程
        async delSchedule() {
            try {
                this.isSubmitting = true;
                const apiUrl = this.tableType === 0 ? 'competition' : 'competitionSchedule';
                const res = await this.$http.delete(`/${apiUrl}/${this.delModal.delId}`);
                if(res.status === 200) {
                    this.toastSuccess('刪除成功');
                    this.pagination.currentPage = 1;
                    this.resetData();
                    if(this.tableType === 0) {
                        await this.fetchCompetition();
                    } else {
                        await this.fetchSchedule();
                    }
                }
            } catch(err) {
                this.toastError('賽事刪除失敗，請稍後再試');
            } finally {
                this.isSubmitting = false;
            }
        },
        async exportReportHandler(ids = []) {
            const recordIds = ids.length !== 0 ? ids : _.chain(this.scheduleList).filter({isExport: true}).flatMap('competition_schedule_records').map('id').value();
            try {
                this.isExporting = true;
                const res = await http.get('/competitionScheduleReport', {
                    responseType: 'blob',
                    params: {
                        competition_schedule_record_id: recordIds,
                        is_refresh: 1,
                        competition_type: 'gym',
                        data_type: 'file'
                    }
                });
                if(res.status === 200) {
                    const hasDownload = document.querySelector('[data-download="download"]');
                    if(hasDownload) {
                        document.body.removeChild(hasDownload);
                    }
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('data-download', 'download');
                    link.setAttribute('download', 'report.zip');
                    document.body.appendChild(link);
                    link.click();
                }
            } catch(err) {
                this.toastError('匯出失敗，請稍後再試');
            } finally {
                this.isExporting = false;
            }
        },
        // 匯出
        exportReport(data) {
            this.userSelectModal.show = true;
            this.userSelectModal.userData = data;
        },
        // 全選匯出
        allExportHandler() {
            _.map(this.scheduleList, item => {
                item.isExport = !this.isExportAll;
                return true;
            });
            this.isExportAll = !this.isExportAll;
        },
        resetData() {
            this.competitionModalShow = false;
            this.scheduleModalShow = false;
            this.modal.isEdit = false;
            this.modal.editData = null;
            this.delModal.show = false;
            this.delModal.delId = null;
        }
    }
};
</script>
<style lang="scss" scoped>
@mixin mobile{
    @media (max-width: 767px){
        @content;
    }
}
.table-nav{
    .nav-btn{
        border:        1px solid #dee2e6;
        border-radius: 0;
        &:hover,
        &.active{
            background-color: var(--orange-color);
            color:            white;
        }
    }
}
.table{
    &.table-bordered{
        @include mobile{
            border: 0;
        }
    }
    thead{
        @include mobile{
            display: none;
        }
    }
    &.competition th:last-child{
        width: 270px;
    }
    &.schedule th{
        &:nth-child(3),
        &:nth-child(4){
            width: 130px;
        }
        &:last-child{
            width: 250px;
        }
    }
    tr{
        @include mobile{
            display:       block;
            margin-bottom: 30px;
            border:        1px solid #ccc;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    td{
        @include mobile{
            display: block;
            width:   100% !important;
            border:  0;
        }
        &:first-child{
            @include mobile{
                background-color: #CCC;
            }
        }
        &:before{
            @include mobile{
                content:       attr(data-label);
                position:      relative;
                display:       block;
                margin-bottom: 3px;
                font-weight:   bold;
            }
        }
    }
}
</style>

<template>
    <b-modal v-model="show"
             centered
             no-close-on-backdrop
             @hide="closeModal">
        <template #modal-title>
            <p class="title-text">
                <span v-if="!isEdit">新增</span><span v-else>編輯</span>賽事資料
            </p>
        </template>
        <div class="form-label">
            <span class="label-text">賽事名稱</span>
            <input v-model="form.name"
                   type="text"
                   name="name"
                   class="form-control"
                   :class="{'error-control': errors.has('name:required')}"
                   placeholder="請輸入賽事名稱"
                   v-validate="'required'"/>
        </div>
        <div class="form-label">
            <span class="label-text">開始時間</span>
            <date-picker v-model="form.start_date"
                         name="start_date"
                         type="datetime"
                         value-type="format"
                         format="YYYY-MM-DD HH:mm:ss"
                         :class="{'error-control': errors.has('start_date:required')}"
                         placeholder="請選擇開始時間"
                         :open.sync="isStartPickerOpen"
                         @change="startPickerHandle"
                         v-validate="'required'"/>
        </div>
        <div class="form-label">
            <span class="label-text">結束時間</span>
            <date-picker v-model="form['end_date']"
                         name="end_date"
                         class="end-time"
                         type="datetime"
                         value-type="format"
                         format="YYYY-MM-DD HH:mm:ss"
                         :class="{'error-control': errors.has('end_date')}"
                         :open.sync="isEndPickerOpen"
                         placeholder="請選擇結束時間"
                         @change="endPickerHandle"
                         v-validate="'competitionDateValidate:start_date|required'"/>
            <span v-show="errors.has('end_date:competitionDateValidate')" class="error-text">結束時間不可早於開始時間</span>
        </div>
        <template #modal-footer>
            <button type="button" class="btn gray-btn" @click="closeModal">取消</button>
            <button type="button" class="btn green-btn" @click="addCompetition" :disabled="isLoading">
                <clip-loader v-if="isLoading" class="loading py-1 px-2" color="white" size="15px"/>
                <span v-else>
                    <template v-if="!isEdit">新增</template>
                    <template v-else>儲存</template>
                </span>
            </button>
        </template>
    </b-modal>
</template>
<script>
import CommonSelect from '../Common/CommonSelect';

export default {
    name: 'CompetitionModal',
    props: {
        isLoading: Boolean,
        value: {
            type: Boolean,
            default: true
        },
        isEdit: Boolean,
        editData: {
            type: Object,
            default: {}
        }
    },
    components: {
        CommonSelect
    },
    watch: {
        value(newVal) {
            this.resetValidate();
            this.show = newVal;
        },
        editData: {
            handler(newVal) {
                if(newVal && this.show) {
                    this.form = _.cloneDeep(newVal);
                } else {
                    this.resetForm();
                }
            },
            deep: true
        },
        'form.start_date': function(newVal) {
            if(newVal && this.form.end_date) {
                this.$validator.validate('end_date');
            }
        }
    },
    data() {
        return {
            show: this.value,
            isStartPickerOpen: false,
            isEndPickerOpen: false,
            form: {}
        };
    },
    created() {
        this.$validator.extend('competitionDateValidate', {
            getMessage: '結束時間不能小於開始時間',
            validate: (value) => {
                return this.$moment(value).isSameOrAfter(this.$moment(this.form.start_date));
            }
        });
    },
    methods: {
        closeModal() {
            this.resetForm();
            this.$emit('input', false);
        },
        startPickerHandle(value, type) {
            if(type === 'second') {
                this.isStartPickerOpen = false;
            }
        },
        endPickerHandle(value, type) {
            if(type === 'second') {
                this.isEndPickerOpen = false;
            }
        },
        async addCompetition() {
            const isPass = await this.validate(true);
            if(!isPass) return;

            this.$emit('competition-handle', this.form);
        },
        resetForm() {
            this.form = {};
        }
    }
};
</script>
<style lang="scss" scoped>
.team-title{
    padding:          5px;
    background-color: #EDEDED;
    margin-bottom:    10px;
}
.form-label{
    margin-bottom: 10px;
    &:last-child{
        margin-bottom: 0;
    }
}
.end-time{
    :deep(.mx-input:disabled){
        color: #333;
    }
}
</style>

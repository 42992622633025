<template>
    <div class="select-wrapper">
        <multiselect v-model="selected"
                     :id="labelName"
                     :options="selectList"
                     @input="selectHandle"
                     placeholder="請選擇"
                     selectLabel=""
                     deselectLabel=""
                     selectedLabel=""
                     :searchable="isSearch"
                     :allowEmpty="isAllowEmpty"
                     :label="displayLabel"
                     :disabled="isDisabled">
            <span slot="noResult">無</span>
            <span slot="noOptions">無</span>
        </multiselect>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'CommonSelect',
    props: {
        labelName: {
            type: String,
            default: ''
        },
        isAllowEmpty: {
            type: Boolean,
            default: true
        },
        isSearch: Boolean,
        isDisabled: Boolean,
        selectList: {
            type: Array,
            default: []
        },
        displayLabel: String,
        value: Number,
        findKey: String,
        rowSort: Number,
        isReturnObject: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Object,
            default: null
        }
    },
    components: {
        Multiselect
    },
    watch: {
        selectList(newVal) {
            if(newVal) {
                this.findOption(newVal);
            }
        },
        value: {
            handler: function(newVal) {
                this.findOption(newVal);
            },
            immediate: true
        }
    },
    data() {
        return {
            selected: null
        };
    },
    methods: {
        findOption(newVal) {
            if(newVal === undefined) {
                this.selected = null;
                return;
            }
            const findObj = _.find(this.selectList, item => item[this.findKey] === this.value);
            if(findObj) {
                this.selected = findObj;
            } else {
                this.selected = newVal;
            }
            if(this.isReturnObject && findObj) {
                this.$emit('update:return-object', !!findObj ? findObj : null);
            }
        },
        selectHandle(e) {
            if(this.rowSort !== undefined && this.rowSort !== null) {
                const data = {
                    item: e,
                    rowSort: this.rowSort
                };
                this.$emit('input', data);
            } else {
                this.$emit('input', e ? this.findKey ? e[this.findKey] : e : null);
            }
            if(this.isReturnObject) {
                this.$emit('update:return-object', !!e ? e : null);
            }
        }
    }
};
</script>
